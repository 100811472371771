import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/ryan.parr/Documents/Apps/sprinklr.design/src/templates/mdx-page.js";
import { jsx, Styled } from 'theme-ui';
import Foo from '../../../components/Docs/Foo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Users connect the most with the way they’re spoken to thus we believe in proving a thoughtful and seamless experience through our voice, tone and style. It further define how our brand sounds.`}</p>
    {/*
     ### Tone
     Sprinklr’s voice defines the brand’s personality, but how we speak in different situations is guided by our tone — keeping in mind the varying feelings and motivations of our users.
     [read how we behave](/system/3-tone)
     ### Style
     Once we’ve understood our product’s personality and how it speaks during different situations, it is very important to execute it all in a disciplined way of writing across the platform irrespective of context. Our style guide is a set of rules that compliment our voice and tone.
     [read how we write](/system/3-style)
     ### Voice
     It is what defines the personality of our product and brings it to life by adding a touch of emotion. Our brand also reflects the same beliefs thus when we write, we try to be:
     [read how we talk](/system/3-voice)
     */}
    <Foo title="voice" icon={11} open mdxType="Foo">
  <Styled.p>
    It is what defines the personality of our product and brings it to life by
    adding a touch of emotion. Our brand also reflects the same beliefs thus
    when we write, we try to be:
  </Styled.p>
  <Styled.p>
    <a href="/system/content/voice">read how we talk</a>
  </Styled.p>
    </Foo>
    <Foo title="tone" icon={10} open mdxType="Foo">
  <Styled.p>
    Sprinklr’s voice defines the brand’s personality, but how we speak in
    different situations is guided by our tone — keeping in mind the varying
    feelings and motivations of our users.
  </Styled.p>
  <Styled.p>
    <a href="/system/content/tone">read how we behave</a>
  </Styled.p>
    </Foo>
    <Foo title="style" icon={9} open mdxType="Foo">
  <Styled.p>
    Once we’ve understood our product’s personality and how it speaks during
    different situations, it is very important to execute it all in a
    disciplined way of writing across the platform irrespective of context. Our
    style guide is a set of rules that compliment our voice and tone.
  </Styled.p>
  <Styled.p>
    <a href="/system/content/style">read how we write</a>
  </Styled.p>
    </Foo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      